const firebaseConfig = {
    apiKey: "AIzaSyBi3UkdveY4CgEIQ_cG8qD7XDYOBrkKH7E",
    authDomain: "midterm-2295c.firebaseapp.com",
    projectId: "midterm-2295c",
    storageBucket: "midterm-2295c.appspot.com",
    messagingSenderId: "1074843527458",
    appId: "1:1074843527458:web:0a058e8d4286da5db05d7b",
    measurementId: "G-DKWY82TF01"
};

export default firebaseConfig;